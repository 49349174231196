const Notification = ({ message, type }) => {
  return (
    <div
      className={`notification ${type === 'info' && 'is-info'} ${
        type === 'error' && 'is-danger'
      } ${type === 'success' && 'is-success'}`}
    >
      <span dangerouslySetInnerHTML={{ __html: message }}></span>
    </div>
  );
};

export default Notification;
