import axios from 'axios';
import { useEffect, useState } from 'react';
import BananoIcon from '../components/BananoIcon';
import Banner from '../components/Banner';
import BanWithQR from '../components/BanWithQR';
import Notification from '../components/Notification';
import Collapsable from '../components/Collapsable';

const Opinions = () => {
  const [showResults, setShowResults] = useState(true);
  const [ban, setBan] = useState();
  const [question, setQuestion] = useState();
  const [answers, setAnswers] = useState();
  const [votes, setVotes] = useState();
  const [highestVote, setHighestVote] = useState();
  const [endTime, setEndTime] = useState();
  const [previous, setPrevious] = useState();

  const [step, setStep] = useState(0);
  const [vote, setVote] = useState();
  const [wallet, setWallet] = useState();
  const [update, setUpdate] = useState(false);

  const currentTime = new Date().getTime();

  const goToVoting = () => {
    setStep(0);
    setVote();
    setWallet();
    setShowResults(false);
  };

  const hasVoted = () => {
    setShowResults(true);
    axios
      .post('/count-votes')
      .then((res) => {
        setUpdate(!update);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateStep = (number) => {
    setStep(number);
  };

  useEffect(() => {
    const getQuestion = async () => {
      const res = await axios.get('/question-of-the-week');
      setQuestion(res.data.question);
      setAnswers(res.data.answers);
      setVotes(res.data.votes);
      setBan(res.data.ban);
      setEndTime(res.data.time);
      setPrevious(res.data.previous);

      let highestVote = 0;
      res.data.answers.forEach(
        (answer) =>
          (highestVote =
            highestVote < answer.number ? answer.number : highestVote)
      );
      setHighestVote(highestVote);
    };

    getQuestion();
  }, [update]);

  return (
    <>
      <Banner
        title="TNV Bananopinions"
        subtitle="Monkeys assemble and voice your opinions on these important questions"
      />
      <div className="container">
        <div className="section">
          {endTime <= currentTime && (
            <Notification
              message="This poll has ended. Come back later to give your opinion on an entirely new question."
              type="info"
            />
          )}
          <div className="pb-5">
            <Collapsable title="Opinions FAQs">
              <div className="pb-3">
                <p>
                  <strong>Why should I vote?</strong>
                </p>
                <p>
                  We are creating the definitive list opinions of the Banano
                  community on a couple of important topics all monkeys can't
                  stop arguing about. On top of that, you will also support this
                  faucet because most of the entry fees will be used to fill up
                  the faucet.
                  <br />
                  As a bonus for voting, there will also be a small chance you
                  get some extra Banano when using the faucet for the duration
                  of that poll. This should not be the reason why you should
                  vote, but is a token of gratitude for your contribution.
                </p>
              </div>
            </Collapsable>
          </div>
          <div className="card">
            <div className="card-content">
              <div className="columns">
                <div className="column is-four-fifths">
                  <h3 id="title" className="title">
                    {question}
                  </h3>
                </div>
                {endTime > currentTime && (
                  <div className="column">
                    {showResults ? (
                      <>
                        <button
                          onClick={() => goToVoting()}
                          className="button is-primary is-fullwidth"
                        >
                          Place vote
                        </button>
                        <p className="py-4">
                          {endTime && (
                            <span>
                              Voting open until:{' '}
                              <strong>
                                {new Date(endTime).toLocaleDateString() +
                                  ' ' +
                                  new Date(endTime).toLocaleTimeString()}
                              </strong>
                            </span>
                          )}
                        </p>
                      </>
                    ) : (
                      <button
                        onClick={() => setShowResults(true)}
                        className="button is-fullwidth"
                      >
                        Back to results
                      </button>
                    )}
                  </div>
                )}
              </div>
              {showResults ? (
                <div>
                  {answers &&
                    answers.map((answer) => (
                      <div key={answer.id} className="py-3">
                        <span>
                          <strong>{answer.name}</strong>{' '}
                          {answer.from && `(${answer.from})`}
                        </span>
                        <div className="is-flex is-justify-content-space-between is-align-items-center">
                          <progress
                            className={`progress mb-0 ${
                              answer.number === highestVote && 'is-success'
                            }`}
                            value={answer.number}
                            max={votes}
                            title={`${answer.number} ${
                              answer.number === 1 ? 'vote' : 'votes'
                            }`}
                          ></progress>
                          <span
                            className="ml-2"
                            style={{ width: 85, textAlign: 'right' }}
                          >
                            {votes === 0
                              ? 0
                              : ((answer.number / votes) * 100).toFixed(2)}
                            %
                          </span>
                        </div>
                        <div>
                          <p className="is-size-7">
                            {answer.number} vote{answer.number !== 1 && 's'}
                          </p>
                        </div>
                      </div>
                    ))}
                  <p>
                    Total votes: <strong>{votes}</strong>
                  </p>
                </div>
              ) : (
                <>
                  <ul className="steps is-horizontal has-content-centered is-small">
                    <li
                      className={`steps-segment ${step === 0 && 'is-active'}`}
                    >
                      <span className="steps-marker"></span>
                      <div className="steps-content">
                        <p className="is-size-7-mobile is-size-6-desktop">
                          Explanation
                        </p>
                      </div>
                    </li>
                    <li
                      className={`steps-segment ${step === 1 && 'is-active'}`}
                    >
                      <span className="steps-marker"></span>
                      <div className="steps-content">
                        <p className="is-size-7-mobile is-size-6-desktop">
                          Vote
                        </p>
                      </div>
                    </li>
                    <li
                      className={`steps-segment ${step === 2 && 'is-active'}`}
                    >
                      <span className="steps-marker"></span>
                      <div className="steps-content">
                        <p className="is-size-7-mobile is-size-6-desktop">
                          Wallet
                        </p>
                      </div>
                    </li>
                    <li
                      className={`steps-segment ${step === 3 && 'is-active'}`}
                    >
                      <span className="steps-marker"></span>
                      <div className="steps-content">
                        <p className="is-size-7-mobile is-size-6-desktop">
                          Payment
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div>
                    {step === 0 && (
                      <div className="is-flex is-justify-content-center is-align-items-center">
                        <div className="column is-three-fifths-tablet is-two-fifths-desktop">
                          <div className="px-1">
                            <div className="my-4">
                              Voting in this poll will costs{' '}
                              <span className="is-inline-flex">
                                1.00~ <BananoIcon />
                              </span>{' '}
                              and the following steps will help you in making
                              your vote as easy as possible. Let's go!
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <span>&nbsp;</span>
                              <button
                                onClick={() => updateStep(1)}
                                className="button is-success mt-5"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {step === 1 && (
                      <div className="is-flex is-justify-content-center is-align-items-center">
                        <div className="column is-three-fifths-tablet is-two-fifths-desktop">
                          <div className="px-1">
                            <p className="subtitle">
                              Who do you want to vote for?
                            </p>
                            {answers.map((answer) => (
                              <div key={answer.id} className="control my-1">
                                <label className="radio">
                                  <input
                                    type="radio"
                                    value={answer.price}
                                    name="answer"
                                    checked={vote === answer.price}
                                    onChange={(e) => setVote(e.target.value)}
                                    className="mr-1"
                                  />
                                  <strong>{answer.name}</strong>{' '}
                                  {answer.from && `(${answer.from})`}
                                </label>
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() => updateStep(0)}
                            className="button mt-5"
                          >
                            Back
                          </button>
                          <button
                            onClick={() => updateStep(2)}
                            disabled={!vote}
                            className="button is-pulled-right is-success mt-5"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <div className="is-flex is-justify-content-center is-align-items-center">
                        <div className="column is-three-fifths-tablet is-two-fifths-desktop">
                          <div className="px-1">
                            <p className="subtitle">
                              How would you like to pay?
                            </p>
                            <p className="pb-4">
                              Selecting your wallet will help you in next step
                              to make the payment easier. If you are not on the
                              same device as the wallet, please select "Other".
                            </p>
                            <div className="control my-1">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="wallet"
                                  value="kalium"
                                  checked={wallet === 'kalium'}
                                  onChange={(e) => setWallet(e.target.value)}
                                  className="mr-1"
                                />
                                <strong>Kalium (on this phone)</strong>
                              </label>
                            </div>
                            <div className="control my-1">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="wallet"
                                  value="bananovault"
                                  checked={wallet === 'bananovault'}
                                  onChange={(e) => setWallet(e.target.value)}
                                  className="mr-1"
                                />
                                <strong>BananoVault (on this device)</strong>
                              </label>
                            </div>
                            <div className="control my-1">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="wallet"
                                  value="other"
                                  checked={wallet === 'other'}
                                  onChange={(e) => setWallet(e.target.value)}
                                  className="mr-1"
                                />
                                <strong>Other</strong>
                              </label>
                            </div>
                          </div>
                          <button
                            onClick={() => updateStep(1)}
                            className="button mt-5"
                          >
                            Back
                          </button>
                          <button
                            onClick={() => updateStep(3)}
                            disabled={!wallet}
                            className="button is-pulled-right is-success mt-5"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="is-flex is-justify-content-center is-align-items-center">
                        <div className="column is-three-fifths-tablet is-two-fifths-desktop">
                          <div className="px-1">
                            <p className="mb-4">
                              Finalize your vote for{' '}
                              <strong>
                                {
                                  answers.filter(
                                    (answer) => answer.price === vote
                                  )[0].name
                                }
                              </strong>
                            </p>
                            {wallet === 'kalium' && (
                              <div>
                                <a
                                  href={`ban:${ban}?amount=${vote}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="button is-info is-fullwidth"
                                >
                                  Pay through Kalium
                                </a>
                              </div>
                            )}
                            {wallet === 'bananovault' && (
                              <div>
                                <a
                                  href={`https://vault.banano.cc/send?to=${ban}&amount=${
                                    vote.substring(0, 1) +
                                    '.' +
                                    vote.substring(1)
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="button is-info is-fullwidth"
                                >
                                  Pay through BananoVault
                                </a>
                              </div>
                            )}
                            {wallet === 'other' && (
                              <div className="has-text-centered">
                                <p>Pay exactly</p>
                                <div className="is-inline-flex is-align-items-center my-2">
                                  <strong className="word-break">
                                    {vote.substring(0, 1) +
                                      '.' +
                                      vote.substring(1)}
                                  </strong>
                                  <BananoIcon />
                                </div>
                                <p>to</p>
                                <BanWithQR
                                  ban={ban}
                                  qr={`/assets/ban/${vote}.png`}
                                />
                              </div>
                            )}
                            <p className="pt-4">
                              If you completed your payment, you can click
                              "Done" and your vote should automatically show up
                              in the next couple of seconds. If the payment
                              didn't work for you, please try again using a
                              different method.
                            </p>
                          </div>
                          <button
                            onClick={() => updateStep(2)}
                            className="button mt-5"
                          >
                            Back
                          </button>
                          <button
                            onClick={() => hasVoted()}
                            className="button is-pulled-right is-success mt-5"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {previous && (
            <div className="mt-5">
              <Collapsable title="Previous Bananopinions">
                {previous.map(({ question, name, from }) => (
                  <div key={question} className="mb-3">
                    <p>
                      <strong>{question}</strong> {name} {from && `(${from})`}
                    </p>
                  </div>
                ))}
              </Collapsable>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Opinions;
