import { useState } from 'react';

const Collapsable = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="card">
      <div onClick={() => setOpen(!open)} className="card-header">
        <p className="card-header-title">{title}</p>
        <div className="card-header-icon">
          <span className="icon">
            <i className={`fas fa-angle-${open ? 'up' : 'down'}`}></i>
          </span>
        </div>
      </div>
      <div className={`card-content ${!open && 'is-hidden'}`}>{children}</div>
    </div>
  );
};

export default Collapsable;
