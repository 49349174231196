const Banner = ({ title, subtitle }) => {
  return (
    <section className="hero is-success">
      <div className="hero-body">
        <div className="container">
          {title && <h1 className="title">{title}</h1>}
          {subtitle && <h2 className="subtitle">{subtitle}</h2>}
        </div>
      </div>
    </section>
  );
};

export default Banner;
