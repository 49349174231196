import { Switch, Route } from 'react-router-dom';

import Layout from './components/Layout';
import Faucet from './pages/Faucet';
import Opinions from './pages/Opinions';

const App = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/opinions">
          <Opinions />
        </Route>
        <Route path="/">
          <Faucet />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
