const BanWithQR = ({ ban, qr }) => {
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center">
      <figure className="image is-128x128 mb-1">
        <img src={qr} alt={ban} title={ban} />
      </figure>
      <strong className="word-break" style={{ fontFamily: 'monospace' }}>
        {ban}
      </strong>
    </div>
  );
};

export default BanWithQR;
