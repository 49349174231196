import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import SpotlightSection from '../components/SpotlightSection';
import Banner from '../components/Banner';
import Notification from '../components/Notification';
import BananoIcon from '../components/BananoIcon';
import BanWithQR from '../components/BanWithQR';
import Collapsable from '../components/Collapsable';

const Faucet = () => {
  const [donateAddress, setDonateAddress] = useState();
  const [balance, setBalance] = useState();
  const [refills, setRefills] = useState();
  const [leaderboard, setLeaderboard] = useState();
  const [payout, setPayout] = useState();
  const [ban, setBan] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const captchaRef = useRef(null);

  const clickHandler = async (token) => {
    try {
      setLoading(true);
      const { data } = await axios.post('/redeem', {
        ban,
        token,
      });
      setMessage(data);
      setDone(true);
    } catch (e) {
      setMessage(e.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getStats = async () => {
      const res = await axios.get('/stats');
      setBalance(res.data.balance);
      setPayout(res.data.payout);
      setDonateAddress(res.data.address);
    };

    const getRefills = async () => {
      const res = await axios.get('/refills');
      setRefills(res.data.refills);
      setLeaderboard(res.data.leaderboard);
    };

    getStats();
    getRefills();
  }, []);
  return (
    <>
      <Banner
        title="TNV Banano Faucet"
        subtitle="Fill up your wallet every day"
      />
      <div className="container">
        <div className="section">
          <div id="dynamic-message"></div>
          {message && <Notification {...message} />}
          {/* <Notification
              message="Spooktober is upon us! Vote now in the scary new poll and you might get some more treats if you are lucky!"
              type="info"
            /> */}
          <div className="pb-5">
            <Collapsable title="Faucet FAQs">
              <div className="pb-3">
                <p>
                  <strong>How to use the faucet?</strong>
                </p>
                <p>
                  Fill in Banano account in the input field below and hit the
                  "Redeem" button. A small share of the balance will immediately
                  be transfered to your account.
                </p>
              </div>
              <div className="pb-3">
                <p>
                  <strong>How many times can I redeem?</strong>
                </p>
                <p>
                  You can redeem once every day. At midnight UTC the faucet is
                  reset and you can once again claim. This means you don't have
                  to wait exactly 24 hours to make your life a little easier.
                  Just check it out every day and never miss anything.
                </p>
              </div>
              <div className="pb-3">
                <p>
                  <strong>Why should I refill?</strong>
                </p>
                <p>
                  That is entirely up to you, but the more Banano is in the
                  faucet, the higher the payout will become. It will also keep
                  the faucet alive and as an extra incentive you can promote
                  your own MonKey in the refill section below the Faucet itself.
                  Only accounts who refilled one Banano or more will be
                  spotlighted.
                  <br />
                  Note: A refill can take up to 10 minutes before the amount is
                  added to the faucet balance and the spotlight section is only
                  calculated on the last 5000 transactions.
                </p>
              </div>
              <div>
                <p>
                  <strong>Why should I donate?</strong>
                </p>
                <p>
                  To support me in covering the server costs first and foremost.
                  I will also try to develop new ways of earning/using your
                  Banano in ways which can be fun for everyone if I know the
                  demand is high enough for it.
                </p>
              </div>
            </Collapsable>
          </div>
          <div className="card">
            <div className="card-content">
              {!loading ? (
                <div className="columns">
                  <div className="column is-four-fifths">
                    <input
                      className="input"
                      onChange={(e) => setBan(e.target.value)}
                      placeholder="ban address (ban_...)"
                    />
                  </div>
                  <div className="column">
                    <button
                      className="button is-primary is-fullwidth"
                      onClick={() => captchaRef.current.execute()}
                    >
                      Redeem
                    </button>
                    <HCaptcha
                      sitekey={process.env.REACT_APP_HCAPTCHA_KEY}
                      size="invisible"
                      onVerify={clickHandler}
                      ref={captchaRef}
                    />
                  </div>
                </div>
              ) : (
                <div className="columns">
                  <div className="column is-flex is-justify-content-center">
                    <span className="icon has-text-success is-large">
                      {done ? (
                        <i className="fas fa-check fa-2x"></i>
                      ) : (
                        <i className="fas fa-spinner fa-2x fa-pulse"></i>
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="columns">
                <div className="column">
                  <div className="is-flex">
                    <span>
                      Current payout: <strong>{payout}</strong>
                    </span>
                    <BananoIcon />
                  </div>
                  <p className="is-size-7">
                    Vote on this week's opinion for a small, daily chance of a
                    higher payout for the duration of that opinion.
                  </p>
                </div>
                <div className="column is-flex is-align-items-flex-start">
                  <span>
                    Current balance: <strong>{balance}</strong>
                  </span>
                  <BananoIcon />
                </div>
              </div>
              <div>
                <p>Fill up this faucet:</p>
                <div className="p-5">
                  <BanWithQR ban={donateAddress} qr="/assets/ban/faucet.png" />
                </div>
              </div>
            </div>
          </div>
          {refills && refills.length > 0 && (
            <SpotlightSection title="Refill spotlight" refills={refills} />
          )}
          {leaderboard && leaderboard.length > 0 && (
            <SpotlightSection title="Top refillers" refills={leaderboard} />
          )}
        </div>
      </div>
    </>
  );
};

export default Faucet;
