import BananoIcon from './BananoIcon';
import Collapsable from './Collapsable';

const SpotlightSection = ({ title, refills }) => {
  return (
    <>
      <section className="my-5 is-hidden-tablet">
        <Collapsable title={title}>
          {refills.map((refill) => (
            <div
              className="is-flex is-justify-content-space-between is-align-items-center"
              key={refill.timestamp}
            >
              <figure className="image is-64x64">
                <img
                  src={`https://monkey.banano.cc/api/v1/monkey/${refill.account}`}
                  alt={refill.account}
                  title={`${refill.account} at ${new Date(
                    refill.timestamp * 1000
                  ).toLocaleString()}`}
                />
              </figure>
              <div className="is-flex">
                <span>+{refill.amount}</span>
                <BananoIcon />
              </div>
            </div>
          ))}
        </Collapsable>
      </section>
      <section className="my-6 is-hidden-mobile">
        <h3 className="subtitle">{title}:</h3>
        <div className="columns">
          {refills.map((refill) => (
            <div className="column is-one-fifth" key={refill.timestamp}>
              <div className="card">
                <div className="card-image">
                  <img
                    src={`https://monkey.banano.cc/api/v1/monkey/${refill.account}`}
                    alt={refill.account}
                    title={`${refill.account} at ${new Date(
                      refill.timestamp * 1000
                    ).toLocaleString()}`}
                  />
                </div>
                <div className="card-footer">
                  <div className="card-footer-item is-flex">
                    <span>+{refill.amount}</span>
                    <BananoIcon />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default SpotlightSection;
