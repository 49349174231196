import { useState } from 'react';
import { Link } from 'react-router-dom';
import BanWithQR from './BanWithQR';
import styles from './Layout.module.css';

const Layout = ({ children }) => {
  const [darkMode, setDarkMode] = useState(!!localStorage.getItem('dark-mode'));

  const toggleDarkMode = (enable) => {
    setDarkMode(enable);

    if (enable) {
      localStorage.setItem('dark-mode', 'true');
    } else {
      localStorage.removeItem('dark-mode');
    }
  };

  return (
    <div className={`${styles.Layout} ${darkMode ? 'dark-mode' : ''}`}>
      <header className={styles.Header}>
        <nav className="navbar is-success">
          <div className="container">
            <div className="navbar-brand">
              <div className="navbar-item">
                <a href="https://banano.cc/" target="_blank" rel="noreferrer">
                  <img src="/assets/banano-logo.svg" alt="Banano" height="25" />
                </a>
              </div>
              <Link to="/" className="navbar-item">
                Faucet
              </Link>
              <Link to="/opinions" className="navbar-item">
                Opinions
              </Link>
            </div>
            <div className="navbar-end">
              {!darkMode ? (
                <span
                  onClick={() => toggleDarkMode(true)}
                  className="icon is-large"
                >
                  <i className="fas fa-moon"></i>
                </span>
              ) : (
                <span
                  onClick={() => toggleDarkMode(false)}
                  className="icon is-large"
                >
                  <i className="fas fa-sun"></i>
                </span>
              )}
            </div>
          </div>
        </nav>
      </header>
      <main className={styles.Main}>{children}</main>
      <footer className="footer has-text-centered">
        <div className="container">
          <div className="content">
            <p className="subtitle">Support</p>
            <div className="columns is-vcentered">
              <div className="column">
                <p>Development</p>
                <BanWithQR
                  ban="ban_1gscd7w9bke8ex8hbtyspw5eqamjpdn8xop593w9uhuqmoumk4k7ucht8gs3"
                  qr="/assets/ban/development.png"
                />
              </div>
              <div className="column">
                <p>Hosting</p>
                <BanWithQR
                  ban="ban_3yg1sy4o99qswokjxso54wghhsuxfmi4fp5jt8iomrc5asfd8trj6g1tjq64"
                  qr="/assets/ban/hosting.png"
                />
              </div>
              <div className="column">
                <a
                  href="https://www.buymeacoffee.com/TheNewVigilante"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                    alt="Buy Me A Coffee"
                    style={{ height: 60, width: 217 }}
                  />
                </a>
              </div>
              <div className="column">
                <img
                  src="/assets/brave.png"
                  alt="Brave Rewards"
                  style={{ height: 60 }}
                />
              </div>
            </div>

            <p style={{ wordBreak: 'normal' }}>
              These are private accounts and will be used for additional
              development (and server costs). To fill up the faucet, use the
              address underneath the redeem button.
            </p>
            <p>
              Created by: TNV
              <br />
              Contact:{' '}
              <a href="mailto:bananofaucet@gmail.com">bananofaucet@gmail.com</a>
            </p>
          </div>
        </div>
        <p className={`${styles.BottomOfFooter} px-5`}>
          This site is protected by hCaptcha and its{' '}
          <a href="https://hcaptcha.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://hcaptcha.com/terms">Terms of Service</a> apply.
        </p>
      </footer>
    </div>
  );
};

export default Layout;
